<template>
	<div class="card-container" v-if="!currentQuarterTargetExceeded">
		<v-overlay
			:opacity="0.15"
			:value="loader"
			absolute
		>
			<v-row>
				<v-col class="text-center">
					<v-progress-circular
						color="primary"
						indeterminate
						size="40"
						width="6"
					/>
				</v-col>
			</v-row>
		</v-overlay>
		<v-card elevation="6" class="mx-auto">
			<v-card-text>
				<div class="font-lg black--text darken-1">{{ label }}</div>
				<div class="d-flex flex-row align-center justify-space-between align-stretch mt-3">
					<div class="d-flex flex-row align-items-center">
						<div ref="currentCommission" class="font-lg" :class="currentQuarterCommissionLabelColor">{{ '$' + formatThisNumber(currentQuarterCommissionValue,'0,0') }}</div>
						<v-icon :class="currentQuarterCommissionIcon" size="20"/>
					</div>
					<div class="d-flex flex-column align-items-center">
						<div class="grey--text font-lg">{{ '$' + formatThisNumber(currentQuarterCommissionDifferenceValue,'0,0') }}</div>
						<v-icon class="mdi mdi-arrow-right" size="30"/>
					</div>
					<div class="d-flex flex-row align-items-center">
						<div ref="excellenceCommission" class="font-lg amber--text text--darken-4">{{ '$' + formatThisNumber(quarterExcellenceCommissionValue,'0,0') }}</div>
						<v-icon class="mdi mdi-trophy-award amber--text text--darken-4" size="20"/>
					</div>
				</div>
				<div class="error-container" v-if="loadingError">
					<v-alert dense outlined type="error" class="mt-3 mb-0">{{ errorMessage }}</v-alert>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import {numberFormat} from "Helpers/helpers";
import {api} from 'Api';
export default {
	name: "CurrentCommission",
	props: ['conditions'],
	data() {
		return {
			loader: true,
			currentQuarterTargetExceeded: false,
			currentQuarterCommissionValue: 0,
			quarterExcellenceCommissionValue: 0,
			errorMessage: null,
			label: '',
			loadingError: false
		}
	},
    computed: {
		commissionLabel() {
			return 'message.yourCommissionThisQuarter'
		},
		currentQuarterCommissionDifferenceValue() {
			return 	this.currentQuarterCommissionValue - this.quarterExcellenceCommissionValue
		},
		currentQuarterCommissionLabelColor() {
			const pct = this.currentQuarterCommissionValue / this.quarterExcellenceCommissionValue
			if(pct < 0.7){
				return 'red--text text--darken-1'
			} else if(pct >= 0.7 && pct < 1){
				return 'yellow--text text--darken-2'
			} else {
				return 'green--text text--darken-1'
			}
		},
		currentQuarterCommissionIcon() {
			if(this.currentQuarterCommissionValue < this.quarterExcellenceCommissionValue)
				return 'mdi mdi-emoticon-sad-outline red--text text--darken-1'
		},
    },
	methods: {
		formatThisNumber (value,format) {
			return numberFormat(value,format)
		},
		getValues() {
			return new Promise((resolve, reject) => {
				const url = '/sparkline/card/gm-current-commission'
				if(url) {
					api
						.get(url, {
							params: {
								conditions: this.conditions ? this.conditions : []
							}
						})
						.then((response) => {
							const data = response.data.data
							resolve(data)
						})
						.catch((error) => {
							reject(error)
						})
				} else {
					reject('error')
				}
			})
		}
	},
	mounted() {
		this.getValues()
			.then((data) => {
				this.currentQuarterCommissionValue = data.currentQuarterCommissionValue
				this.quarterExcellenceCommissionValue = data.quarterExcellenceCommissionValue
				this.label = data.label
				this.loader = false
			})
			.catch((error) => {
				this.loader = false
				this.loadingError = true
				this.errorMessage = error
			})
	}
}
</script>

<style scoped>
.card-container {
	position: relative;
}
</style>